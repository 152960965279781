import React from 'react'
import Footer from './Footer'
import Navigation from './Navigation'

function Terms() {
  return (
 <>
 <Navigation/>
    <div className="container">
        <div className="entry-content wp-block-post-content has-global-padding is-layout-constrained wp-block-post-content-is-layout-constrained" bis_skin_checked="1">
            <p>Last updated: 17 April 2024</p>
            
            
            
            <p>Please read these Terms of Use (“Terms”, “Terms of Use”) carefully before using the https://spinsnz.com website (the “Service”) operated by https://spinsnz.com (“us”, “we”, or “our”).</p>
            
            
            
            <p>Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.</p>
            
            
            
            <p>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.</p>
            
            
            
            <p>Before continuing to download the application, please read our Terms of Service. By downloading and using our application, you agree to the following:</p>
            
            
            
            <ul>
            <li>You have fully read and understood our Terms of Service.</li>
            
            
            
            <li>You agree to use our service lawfully.</li>
            
            
            
            <li>You consent to the processing of your personal data as specified in our privacy policy.</li>
            </ul>
            
            
            
            <p>You have reviewed and agreed to our Responsible Gaming Rules.</p>
            
            
            
            <h2 className="wp-block-heading">Key Provisions</h2>
            
            
            
            <p>1.1. These Terms of Service establish the rules for using the interactive services (“Services”) offered by the Service Provider through the online platform https://spinsnz.com (“Service”).</p>
            
            
            
            <p>1.2. The services offered by the Service Provider include providing registered users (“Participants”) access to a gaming platform intended for entertainment purposes. Users can interact, exchange information, and discuss topics related to games.</p>
            
            
            
            <p>1.3. Within the offered services, Participants can engage in games where the outcome depends on their reaction speed, agility, and perception. To win, a player must score the most points.</p>
            
            
            
            <p>1.4. Using the services offered through the Service and applying for membership in the Service signifies acknowledgment and acceptance of the terms set forth in this document. Users who access the Service without registering as Participants are still required to adhere to these Terms of Service, as specified in the Electronic Services Act.</p>
            
            
            
            <p>1.5. The Service Provider emphasizes that no individual agreements are concluded with the Users of the Service or Participants. All users of the services offered by the Service Provider through the Service are obliged to comply with these Terms of Service.</p>
            
            
            
            <p>1.6. Full use of all features of the games available through the Service depends on the technical compatibility of the user’s computer or mobile device with the Service.</p>
            
            
            
            <p>1.7. The Service Provider is not responsible for the unavailability of the Service due to factors beyond its control or unforeseen circumstances.</p>
            
            
            
            <h2 className="wp-block-heading">Responsible Gaming Rules</h2>
            
            
            
            <p>The main goal of our online service is to provide entertainment to users and expand their knowledge about games. By making this service publicly available, we hope that all users will use the offered services for these purposes. However, it is possible that for some users, these services may turn from a source of entertainment into an excessive use of time. Such cases are rare, but they can be avoided by following these basic rules:</p>
            
            
            
            <ul>
            <li>Play for pleasure.</li>
            
            
            
            <li>Understand that rating points have no real value and should be regarded as a source of personal satisfaction.</li>
            
            
            
            <li>Seek victory, but avoid revenge.</li>
            
            
            
            <li>Refrain from playing when you feel sad, tired, or frustrated.</li>
            </ul>
            
            
            
            <h2 className="wp-block-heading">Contact Us</h2>
            
            
            
            <p>If you have any questions about these Terms, please contact us.</p>
            </div>
    </div>
    <Footer/>
    </>
  )
}

export default Terms